import { mapGetters, mapMutations } from 'vuex'

import ChatUserStep from '@/modules/jobs/components/ChatUserStep.vue'
import SocialSPaceUserStep from '@/modules/jobs/components/SocialSpaceUserStep.vue'
import ResultStep from '@/modules/jobs/components/ResultStep.vue'
import JobsCategoryItem from '@/modules/jobs/components/JobsCategoryItem.vue'

export default {
  name: 'JobRegister',
  data () {
    return {}
  },
  components: {
    ChatUserStep,
    SocialSPaceUserStep,
    ResultStep,
    JobsCategoryItem
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      profile: 'auth/profile',
      step: 'user/step'
    })
  },
  created () {
    if (this.isAuthenticated) {
      this.changeStep(2)
    } else {
      this.changeStep(1)
    }
  },
  methods: {
    ...mapMutations({
      changeStep: 'user/changeStep',
      changeShowRecoveryPopup: 'auth/changeShowRecoveryPopup',
      changeRegistrationPopup: 'auth/changeRegistrationPopup',
      changeLoginByQRPopup: 'auth/changeLoginByQRPopup'
    }),
    openRegistrationPopup () {
      this.changeRegistrationPopup({ show: true, type: 'jobs' })
    },
    openQRCodePopup () {
      this.changeLoginByQRPopup({ show: true, type: 'jobs' })
    }
  }
}
